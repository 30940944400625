import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import { BodyContainer } from '../../styles/pageStyles/commonStyle';

import Head from 'next/head';

import axios from 'axios';
import { parseUrl } from 'next/dist/shared/lib/router/utils/parse-url';
import StaticPageTitle from '../../components/PageTitle/StaticPageTitle';
import { apiConstants } from '../../constants/apiConstants';
import { PageDataInterface } from '../../Interface/PageDataInterface';
import StaticPageLayout from '../../Layout/StaticPageLayout';
import { getUserInfoSSR } from '../../utils/cf';

interface Props {
  meta: PageDataInterface;
  pageList: any;
}

const TOC = ({ meta, pageList }: Props) => {
  return (
    <div>
      {meta ? (
        <Head>
          <title>{meta?.title} - Driffle</title>
          {meta.featured_image ? (
            <link rel="icon" type="image/png" href={meta?.featured_image} />
          ) : (
            ''
          )}
          <meta name="description" content={meta?.meta_description} />
          <link rel="canonical" href={`https://driffle.com/${meta?.slug}`} />
          <meta
            httpEquiv="last-modified"
            content={
              meta.updated
                ? new Date(meta?.updated).toDateString()
                : new Date('2021-12-13T04:51:17.000Z').toDateString()
            }
          />
        </Head>
      ) : (
        ''
      )}
      <Header />
      <Navbar />
      <StaticPageLayout pageList={pageList}>
        <BodyContainer id="refund-policy">
          <StaticPageTitle title={meta?.title || 'Terms and Conditions'} />
          <div
            dangerouslySetInnerHTML={{
              __html: meta && meta.content ? meta.content : '',
            }}
          ></div>
        </BodyContainer>
      </StaticPageLayout>
      <Footer />
    </div>
  );
};

export async function getServerSideProps(context: any) {
  const REGION = process.env.REGION ?? '';
  const { req } = context;
  const { pathname } = parseUrl(req.url);
  const pageListUrl =
    apiConstants.ssrServer.server.replace('[--REGION--]', `${REGION}`) +
    '/public/page/all';
  const url =
    apiConstants.ssrServer.server.replace('[--REGION--]', `${REGION}`) +
    `/public/pages/${
      pathname.split('/')[pathname.split('/').length - 1]?.split('.')[0]
    }`;
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);

    let getPageList = await axios.get(pageListUrl);
    let pageDetails = await axios.get(url);
    //  const data = (await axios.get(url)).data;
    const status = pageDetails?.status;
    const pageListStatus = getPageList?.status;
    if (
      status == 404 ||
      status == 400 ||
      status == 500 ||
      pageListStatus == 404 ||
      pageListStatus == 400 ||
      pageListStatus == 500
    )
      return {
        notFound: true,
      };

    return {
      props: {
        meta: pageDetails?.data?.data,
        pageList: getPageList?.data?.data,
        reduxInitialState: userInfo,
      },
      // validate: 10,
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}

export default TOC;
